import { zodResolver } from "@hookform/resolvers/zod";
import { ApiLocale, CurrencyCode } from "@twocontinents/shared";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { useGetPackageHolidays, useUpdatePackageHoliday } from "../data-access";

const UpdatePackageHolidayFormSchema = z.object({
  id: z.coerce.number(),
  description: z.string(),
  price: z.coerce.number().nonnegative(),
  currency: z.nativeEnum(CurrencyCode),
  locale: z.nativeEnum(ApiLocale),
});

type UpdatePackageHolidayFormValues = z.infer<
  typeof UpdatePackageHolidayFormSchema
>;

export const useUpdatePackageHolidayForm = () => {
  const { packageHolidays } = useGetPackageHolidays();

  const form = useForm<UpdatePackageHolidayFormValues>({
    resolver: zodResolver(UpdatePackageHolidayFormSchema),
  });

  const id = form.watch("id");
  const packageHoliday = packageHolidays.find(
    (packageHoliday) => packageHoliday.id === id,
  );

  const { updatePackageHoliday, isPending } = useUpdatePackageHoliday();

  const onSubmit = form.handleSubmit((values) => {
    console.log(values);
    updatePackageHoliday({
      id: values.id,
      body: {
        locale: values.locale,
        description: values.description,
        price: {
          amount: values.price,
          currency: values.currency,
        },
      },
    });
  });

  useEffect(() => {
    if (packageHoliday) {
      form.setValue("description", packageHoliday.description);
      form.setValue("price", packageHoliday.price.amount);
      form.setValue("currency", packageHoliday.price.currency);
      form.setValue("locale", packageHoliday.locale);
    }
  }, [packageHoliday]);

  return {
    form,
    onSubmit,
    packageHolidays,
    isPending,
  };
};
