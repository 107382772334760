import { zodResolver } from "@hookform/resolvers/zod";
import { CurrencyCode, DateFormatter } from "@twocontinents/shared";
import dayjs from "dayjs";
import { useState } from "react";
import { DateRange } from "react-day-picker";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  useGetApartmentCalendar,
  useInvalidateGetApartmentCalendar,
} from "../../data-access/get-apartment-calendar";
import { useUpdateApartmentCalendar } from "../../data-access/update-apartment-calendar";
import { ApartmentCalendarEntry } from "../../types";

const CalendarSettingsSchema = z.object({
  dateFrom: z.string().optional(),
  dateTo: z.string().optional(),
  price: z.coerce.number().optional(),
  currency: z.nativeEnum(CurrencyCode).optional(),
  available: z.boolean().optional(),
  minimumStay: z.coerce.number().optional(),
  maximumStay: z.coerce.number().optional(),
});

type CalendarSettings = z.infer<typeof CalendarSettingsSchema>;

// eslint-disable-next-line sonarjs/cognitive-complexity
export const useCalendarSettings = (apartmentId: number) => {
  const form = useForm<CalendarSettings>({
    resolver: zodResolver(CalendarSettingsSchema),
  });

  const [month, setMonth] = useState<Date>(new Date());

  const firstDayOfTheMonthMinusWeek = dayjs(month)
    .startOf("month")
    .subtract(7, "days")
    .toDate();
  const lastDayOfTheMonthPlusWeek = dayjs(month)
    .endOf("month")
    .add(7, "days")
    .toDate();

  const dateFrom = form.watch("dateFrom");
  const dateTo = form.watch("dateTo");
  const available = form.watch("available");
  const price = form.watch("price");
  const currency = form.watch("currency");
  const minimumStay = form.watch("minimumStay");
  const maximumStay = form.watch("maximumStay");

  const setAvailable = () => {
    form.setValue("available", available ? undefined : true);
  };

  const setNotAvailable = () => {
    form.setValue("available", available === false ? undefined : false);
  };

  const setDateTo = (date: Date | undefined) => {
    form.setValue("dateTo", DateFormatter.formatToYYYYMMDD(date));
  };

  const handleRangeChange = (range?: DateRange) => {
    form.setValue(
      "dateFrom",
      range?.from ? DateFormatter.formatToYYYYMMDD(range.from) : undefined,
    );
    form.setValue(
      "dateTo",
      range?.to ? DateFormatter.formatToYYYYMMDD(range.to) : undefined,
    );
  };

  const { calendar } = useGetApartmentCalendar(
    apartmentId,
    DateFormatter.formatToYYYYMMDD(firstDayOfTheMonthMinusWeek),
    DateFormatter.formatToYYYYMMDD(lastDayOfTheMonthPlusWeek),
  );

  const { updateCalendar, isPending } = useUpdateApartmentCalendar();

  const entries = calendar?.entries ?? [];

  const entriesFromSelectedRange = entries.filter((entry) => {
    if (!dateFrom || !dateTo) {
      return false;
    }
    const entryDate = DateFormatter.formatToYYYYMMDD(entry.date);
    return entryDate >= dateFrom && entryDate <= dateTo;
  });

  const pricesPlaceholder = getPlaceholder(
    entriesFromSelectedRange,
    (entry) => (entry ? entry.price.amount : 0),
    "price",
  );

  const minimumStayPlaceholder = getPlaceholder(
    entriesFromSelectedRange,
    (entry) => (entry ? entry.minimumStay : 0),
    "minimumStay",
  );

  const maximumStayPlaceholder = getPlaceholder(
    entriesFromSelectedRange,
    (entry) => (entry ? entry.maximumStay : 0),
    "maximumStay",
  );

  const { invalidate } = useInvalidateGetApartmentCalendar();

  const onSuccessfulUpdate = async () => {
    await invalidate(
      apartmentId,
      DateFormatter.formatToYYYYMMDD(firstDayOfTheMonthMinusWeek),
      DateFormatter.formatToYYYYMMDD(lastDayOfTheMonthPlusWeek),
    );
    form.setValue("dateFrom", undefined);
    form.setValue("dateTo", undefined);
  };

  const handlePriceSave = () => {
    if (dateFrom && dateTo && price && currency) {
      updateCalendar(
        {
          apartmentId,
          body: {
            price,
            currency,
            dateFrom: DateFormatter.formatToYYYYMMDD(dateFrom),
            dateTo: DateFormatter.formatToYYYYMMDD(dateTo),
          },
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSuccess: onSuccessfulUpdate,
        },
      );
    }
  };

  const handleAvailabilitySave = () => {
    if (dateFrom && dateTo && available !== undefined) {
      updateCalendar(
        {
          apartmentId,
          body: {
            available,
            dateFrom: DateFormatter.formatToYYYYMMDD(dateFrom),
            dateTo: DateFormatter.formatToYYYYMMDD(dateTo),
          },
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSuccess: onSuccessfulUpdate,
        },
      );
    }
  };

  const handleMinimumStaySave = () => {
    if (dateFrom && dateTo && minimumStay !== undefined) {
      updateCalendar(
        {
          apartmentId,
          body: {
            minimumStay,
            dateFrom: DateFormatter.formatToYYYYMMDD(dateFrom),
            dateTo: DateFormatter.formatToYYYYMMDD(dateTo),
          },
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSuccess: onSuccessfulUpdate,
        },
      );
    }
  };

  const handleMaximumStaySave = () => {
    if (dateFrom && dateTo && maximumStay !== undefined) {
      updateCalendar(
        {
          apartmentId,
          body: {
            maximumStay,
            dateFrom: DateFormatter.formatToYYYYMMDD(dateFrom),
            dateTo: DateFormatter.formatToYYYYMMDD(dateTo),
          },
        },
        {
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onSuccess: onSuccessfulUpdate,
        },
      );
    }
  };

  const priceSaveButtonDisabled =
    !dateFrom || !dateTo || (!price && price !== 0) || !currency;

  const availabilitySaveButtonDisabled =
    !dateFrom || !dateTo || available === undefined;

  const minimumStaySaveButtonDisabled =
    !dateFrom || !dateTo || (!minimumStay && minimumStay !== 0);

  const maximumStaySaveButtonDisabled =
    !dateFrom || !dateTo || (!maximumStay && maximumStay !== 0);

  return {
    form,
    formattedDateTo: dateTo
      ? DateFormatter.formatToYYYYMMDD(dateTo)
      : undefined,
    formattedDateFrom: dateFrom
      ? DateFormatter.formatToYYYYMMDD(dateFrom)
      : undefined,
    setDateTo,
    month,
    setMonth,
    calendar,
    handleRangeChange,
    pricesPlaceholder,
    minimumStayPlaceholder,
    maximumStayPlaceholder,
    setAvailable,
    setNotAvailable,
    available,
    isPending,
    valid: form.formState.isValid,
    priceSaveButtonDisabled,
    availabilitySaveButtonDisabled,
    minimumStaySaveButtonDisabled,
    maximumStaySaveButtonDisabled,
    handlePriceSave,
    handleAvailabilitySave,
    handleMinimumStaySave,
    handleMaximumStaySave,
  };
};

const getPlaceholder = (
  entries: ApartmentCalendarEntry[],
  selector: (entry?: ApartmentCalendarEntry) => number | string,
  sortingField: keyof ApartmentCalendarEntry,
) => {
  const sortedEntries = entries.toSorted((a, b) => {
    if (
      typeof a[sortingField] === "number" &&
      typeof b[sortingField] === "number"
    ) {
      return a[sortingField] - b[sortingField];
    }
    return 0;
  });
  return `${selector(sortedEntries[0])} - ${selector(sortedEntries.at(-1))}`;
};
