import { Select } from "@twocontinents/dashboard/shared";

import { PackageHoliday } from "../types";

interface AgeGroupSelectProps {
  value?: string;
  packageHolidays: PackageHoliday[];
  onValueChange: (id?: number) => void;
  defaultOpen?: boolean;
}

export const PackageHolidaySelect = ({
  onValueChange,
  packageHolidays,
  defaultOpen,
  value,
}: AgeGroupSelectProps) => {
  return (
    <Select
      onValueChange={(value) => onValueChange(Number.parseInt(value))}
      defaultOpen={defaultOpen}
      value={value}
    >
      <Select.Trigger className="max-w-md h-max min-h-10">
        <Select.Value placeholder="Choose Package Holiday" />
      </Select.Trigger>
      <Select.Content>
        {packageHolidays.map((packageHoliday) => (
          <Select.Item
            value={packageHoliday.id.toString()}
            key={packageHoliday.id}
          >
            {packageHoliday.description}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  );
};
