import {
  ApiLocaleSelect,
  CurrencyCodeSelect,
  Form,
  FormItem,
  Input,
  SettingCard,
} from "@twocontinents/dashboard/shared";
import { CurrencyCode } from "@twocontinents/shared";

import { PackageHolidaySelect } from "./package-holiday-select";
import { useUpdatePackageHolidayForm } from "./use-update-package-holiday-form";

export const UpdatePackageHolidayForm = () => {
  const { packageHolidays, form, onSubmit, isPending } =
    useUpdatePackageHolidayForm();

  return (
    <Form {...form}>
      <form onSubmit={onSubmit}>
        <Form.Fields>
          <Form.Field
            control={form.control}
            name="id"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-25">Offer</FormItem.Label>
                  <FormItem.Control>
                    <PackageHolidaySelect
                      packageHolidays={packageHolidays}
                      onValueChange={field.onChange}
                      value={field?.value?.toString()}
                    />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="description"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-25">
                    Description
                  </FormItem.Label>
                  <FormItem.Control>
                    <Input {...field} />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="locale"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-25">Locale</FormItem.Label>
                  <FormItem.Control>
                    <ApiLocaleSelect
                      onValueChange={field.onChange}
                      value={field?.value}
                    />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="price"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-25">Price</FormItem.Label>
                  <FormItem.Control>
                    <Input type="number" {...field} step={0.01} />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
          <Form.Field
            control={form.control}
            name="currency"
            render={({ field }) => (
              <FormItem>
                <FormItem.Row>
                  <FormItem.Label className="min-w-25">Currency</FormItem.Label>
                  <FormItem.Control>
                    <CurrencyCodeSelect
                      currencies={Object.values(CurrencyCode)}
                      onValueChange={field.onChange}
                      value={field?.value}
                    />
                  </FormItem.Control>
                </FormItem.Row>
                <FormItem.Message />
              </FormItem>
            )}
          />
        </Form.Fields>
        <SettingCard.Footer>
          <SettingCard.Button isLoading={isPending}>Submit</SettingCard.Button>
        </SettingCard.Footer>
      </form>
    </Form>
  );
};
