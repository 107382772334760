import { useMutation } from "@tanstack/react-query";
import { toast, usePrivateApiClient } from "@twocontinents/dashboard/shared";
import { components } from "@twocontinents/shared";

import { useInvalidateGetBlackListEntries } from "./get-black-list-entries";

type UpdateBlackListEntryRequest =
  components["schemas"]["UpdateBlackListEntryRequest"];

interface Props {
  ip: string;
  body: UpdateBlackListEntryRequest;
}

export const useUpdateBlackListEntry = () => {
  const apiClient = usePrivateApiClient();
  const { invalidate } = useInvalidateGetBlackListEntries();

  const { mutate, isPending } = useMutation({
    mutationFn: ({ ip, body }: Props) => {
      return apiClient
        .PATCH("/private/black-list/{ip}", {
          params: { path: { ip } },
          body,
        })
        .then((response) => response.data);
    },
    onSuccess: async (_) => {
      await invalidate();
      toast.success("Black list entry has been updated");
    },
    onError: () => {
      toast.error("Could not update Black list entry");
    },
  });

  return { updateBlackListEntry: mutate, isPending };
};
