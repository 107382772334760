import { Layout, SettingCard } from "@twocontinents/dashboard/shared";

import { UpdatePackageHolidayForm } from "./update-package-holiday-form";

export const PackageHolidaysSettingsPage = () => {
  return (
    <Layout header={{ title: "Package holidays settings" }}>
      <SettingCard>
        <SettingCard.Header>
          <SettingCard.Title>Manage your offers</SettingCard.Title>
          <SettingCard.Description>
            Change your package holidays offers settings
          </SettingCard.Description>
        </SettingCard.Header>
        <UpdatePackageHolidayForm />
      </SettingCard>
    </Layout>
  );
};
