import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

const queryKey = () => ["private-package-holidays"];

export const useGetPackageHolidays = () => {
  const apiClient = usePrivateApiClient();

  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/package-holidays/", {})
        .then((response) => response.data),
    queryKey: queryKey(),
  });

  return {
    packageHolidays: data?.packageHolidays ?? [],
    isLoading,
  };
};

export const useInvalidateGetPackageHolidays = () => {
  const queryClient = useQueryClient();

  const invalidate = async () => {
    await queryClient.invalidateQueries({
      queryKey: queryKey(),
    });
  };

  return {
    invalidate,
  };
};
