import { AlertDialog, Button } from "@twocontinents/dashboard/shared";

import { useUpdateBlackListEntry } from "../data-access";
import { BlackListEntry } from "../types";

interface UpdateBlackListEntryAlertDialogProps {
  entry: BlackListEntry;
}

export const UpdateBlackListEntryAlertDialog = ({
  entry,
}: UpdateBlackListEntryAlertDialogProps) => {
  const { updateBlackListEntry, isPending } = useUpdateBlackListEntry();

  const handleSubmit = () => {
    updateBlackListEntry({
      ip: entry.ipAddress,
      body: {
        blacklisted: !entry.blacklisted,
      },
    });
  };

  return (
    <AlertDialog>
      <AlertDialog.Trigger asChild>
        <Button variant={entry.blacklisted ? "positive" : "destructive"}>
          {entry.blacklisted ? "Unblock" : "Block"}
        </Button>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Header>
          <AlertDialog.Title>
            Are you sure you want to {entry.blacklisted ? "unblock" : "block"}{" "}
            this IP?
          </AlertDialog.Title>
          <AlertDialog.Description>
            {entry.ipAddress} will be{" "}
            {entry.blacklisted ? "unblocked" : "blocked"}.
          </AlertDialog.Description>
        </AlertDialog.Header>
        <AlertDialog.Footer>
          <AlertDialog.Cancel>Cancel</AlertDialog.Cancel>
          <AlertDialog.Action onClick={handleSubmit} disabled={isPending}>
            Yes
          </AlertDialog.Action>
        </AlertDialog.Footer>
      </AlertDialog.Content>
    </AlertDialog>
  );
};
