import { useQuery, useQueryClient } from "@tanstack/react-query";
import { usePrivateApiClient } from "@twocontinents/dashboard/shared";

export const useGetBlackListEntries = () => {
  const apiClient = usePrivateApiClient();
  const { data, isLoading } = useQuery({
    queryFn: () =>
      apiClient
        .GET("/private/black-list/")
        .then((res) => res.data?.blackListEntries ?? []),
    queryKey: ["private-black-list-entries"],
  });

  return { blackListEntries: data ?? [], isLoading };
};

export const useInvalidateGetBlackListEntries = () => {
  const queryClient = useQueryClient();

  const invalidate = () => {
    queryClient.invalidateQueries({ queryKey: ["private-black-list-entries"] });
  };

  return { invalidate };
};
